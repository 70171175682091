import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const blocks = document.querySelectorAll('.testimonials-carousel-block');

  if (0 < blocks.length) {
    blocks.forEach((block) => {
      new Swiper(block.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        watchOverflow: true,

        breakpoints: {
          1080: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1340: {
            slidesPerView: 2,
            spaceBetween: 60,
          },
        },

        navigation: {
          prevEl: block.querySelector('.swiper-button-prev'),
          nextEl: block.querySelector('.swiper-button-next'),
        },
      });
    });
  }
});
